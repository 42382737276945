import React from "react";
import CookieConsent from "react-cookie-consent";
import Button from "./Button";
import Headline1 from "./Headline1";
import Text from "./Text";
import { TextUtils } from "../utils";

const shouldBeDisplayed = () => {
  const pathname =
    typeof window !== "undefined"
      ? TextUtils.trimTrailingChars(window.location.pathname, "/")
      : "";

  return !["/privacy", "/imprint"].includes(pathname);
};

const CookieBanner = () =>
  shouldBeDisplayed() ? (
    <CookieConsent
      buttonText={
        <Button light className="w-full">
          In Ordnung!
        </Button>
      }
      enableDeclineButton
      declineButtonText={
        <Button secondary light className="w-full">
          Nicht zustimmen
        </Button>
      }
      setDeclineCookie={false}
      cookieName="gatsby-gdpr-google-analytics"
      overlay
      disableStyles
      overlayClasses="z-50 h-screen w-screen bg-dark bg-opacity-25 fixed flex justify-center"
      containerClasses="max-w-2xl max-h-full overflow-hidden place-self-center items-center p-4
    md:p-10
    border border-light glow bg-content flex flex-col bg-dark bg-opacity-25 backdrop-blur-10"
      contentClasses="flex flex-col overflow-hidden mb-6 md:mb-12"
      buttonWrapperClasses="w-full flex flex-wrap justify-around"
      buttonClasses="flex-grow no-focus"
      declineButtonClasses="no-focus"
    >
      <Headline1 light>Cookie Settings</Headline1>
      <div className="flex-shrink overflow-auto">
        <Text>Schnell die Cookies, dann geht's weiter.</Text>
        <br />
        <Text>
          Sie stehen bei uns im Mittelpunkt. Deshalb verwenden wir bei codeDeck_
          Cookies und andere Technologien, um unsere Sites zuverlässig und
          sicher zu betreiben, deren Performance zu messen und Ihnen relevante
          Inhalte anzuzeigen. Kurz - wir wollen unser Angebot kontinuierlich für
          Sie verbessern.
        </Text>
        <br />
        <Text>
          Damit das funktioniert, sammeln wir Daten über unsere Nutzer und wie
          sie unsere Angebote auf welchen Geräten nutzen. Wenn Sie auf „In
          Ordnung" klicken, sind Sie damit einverstanden, dass wir diese Daten
          an Dritte weitergeben. Falls Sie dem nicht zustimmen, beschränken wir
          uns auf die wesentlichen Cookies und Sie müssen damit leben, dass die
          Anzeige unserer Inhalte nicht für Sie personalisiert ist. Sie können
          diese Einstellungen jederzeit anpassen. Dazu finden Sie einen Link am
          Ende dieser Seite. Weitere Informationen erhalten Sie in unserer{" "}
          <span className="text-white opacity-75 hover:opacity-100 hover:text-light transition duration-300 ease-in-out">
            <a href="/privacy" target="_blank">
              Datenschutzerklärung.
            </a>
          </span>
        </Text>
        <br />
        <Text>
          Wenn Sie mehr darüber erfahren wollen, wie Sie Cookies in Ihren
          Projekten einsetzen können, sprechen Sie uns an. Wir beraten Sie
          gerne.
        </Text>
      </div>
    </CookieConsent>
  ) : (
    <React.Fragment />
  );

CookieBanner.shouldBeDisplayed = shouldBeDisplayed;

export default CookieBanner;

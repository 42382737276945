import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Intro, Section } from "..";
import CrewList from "../CrewList";

const Crew = () => {
  const data = useStaticQuery(graphql`
    query {
      zettl1: file(relativePath: { eq: "codedeck-johannes-zettl-color.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zettl2: file(relativePath: { eq: "codedeck-johannes-zettl.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nala1: file(relativePath: { eq: "codedeck-partner-nala-color.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nala2: file(relativePath: { eq: "codedeck-partner-nala.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      strecker1: file(
        relativePath: { eq: "codedeck-partner-max-strecker-color.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      strecker2: file(
        relativePath: { eq: "codedeck-partner-max-strecker.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholder1: file(
        relativePath: { eq: "codedeck-partner-placeholder-color.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      placeholder2: file(
        relativePath: { eq: "codedeck-partner-placeholder.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      haefner1: file(
        relativePath: { eq: "codedeck-partner-torsten-haefner-color.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      haefner2: file(
        relativePath: { eq: "codedeck-partner-torsten-haefner.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Section color="secondary" id="crew" headline="Crew">
      <div className="space-y-12 lg:grid lg:grid-cols-2 lg:gap-10 lg:space-y-0 mb-14">
        <Intro>
          <p>
            <strong>Dürfen wir vorstellen? Unser Team.</strong> Wir brennen für
            unsere Arbeit und behalten dabei immer die Bedürfnisse unseres
            Kunden im Blick. Ihre Bedürfnisse.
          </p>
          <p>
            Neben eigenen Mitarbeitern haben wir ein starkes Netzwerk aus dem
            wir nach Bedarf ein schlagkräftiges Team zusammenstellen.
          </p>
        </Intro>
      </div>
      <CrewList
        members={[
          {
            name: "Johannes Zettl",
            title: "CEO",
            description:
              'Johannes sammelte mehrere Jahre Erfahrung als Berater und Projektleiter in Produktion und Logistik. "Die vorherrschende Excel-Kultur in großen Unternehmen fand ich nicht mehr zeitgemäß" sagt er. Im Jahr 2016 gründete Johannes ein Unternehmen, um Software zu entwickeln, die Unternehmensprozesse effizienter macht. Johannes ist davon überzeugt, dass durch Software nicht nur viele manuelle Tätigkeiten automatisiert werden können. Auch Fehler und Informationslücken können durch Software reduziert werden. "Gute Software muss Spaß machen und Verschwendung reduzieren."',
            img1: data.zettl1.childImageSharp.fluid,
            img2: data.zettl2.childImageSharp.fluid,
            // linkedInUrl: "https://www.linkedin.com/in/johannes-zettl/",
            // twitterUrl: "https://twitter.com/JohannesZettl",
            // instagramUrl: "https://www.instagram.com/zettlcopter/",
          },
          {
            name: "Max Strecker / Agentur ON",
            title: "UX & UI",
            description:
              "Dank langjähriger Agentur- und Freelanceerfahrung bietet ON ganzheitliche Lösungen rund um Brand, Design und Digital. Wir entwickeln passgenaue Corporate Identities mit Feingefühl für Ästhetik und dem Anspruch, unsere Kunden on Point zu positionieren. Digital-Jobs stammen bei uns aus einer Hand: Dies ermöglicht schlanke Prozesse, bei denen Designaspekte ab der ersten Codezeile mitgedacht werden.",
            img1: data.strecker1.childImageSharp.fluid,
            img2: data.strecker2.childImageSharp.fluid,
            // instagramUrl: "https://www.instagram.com/wir_sind_on/",
            websiteUrl: "https://agentur-on.com/",
          },
          {
            name: "Thorsten Häfner",
            title: "Full Stack Developer",
            description:
              'Thorsten ist seit dem Jahr 2001 IT-Berater und Softwareentwickler. Er arbeitete für verschiedene DAX-notierte Unternehmen in den Branchen Industrie, Handel, Transport, Telekommunikation und Banken. Ob Frontend, Backend oder beides spielt dabei keine Rolle. "Java, PHP, GO, Perl, Python, Javascript, es ist nicht so wichtig, welche Technologie man verwendet. Es geht darum, Probleme für den Kunden zu lösen." Als ausgebildeter Feuerwehrmann und Rettungstaucher hat er gelernt, mit Risiken umzugehen. In seiner Freizeit betrachtet Thorsten die Welt gerne von oben - aus dem Cockpit seiner Piper Pa28.',
            img1: data.haefner1.childImageSharp.fluid,
            img2: data.haefner2.childImageSharp.fluid,
            websiteUrl:
              "https://www.at-aviation.de/index.php/dt_team/thorsten-haefner/",
          },
          {
            name: "Nala vom Mühlenredder",
            title: "Feel Good Manager",
            description:
              "Nala sorgt für gute Laune im Team. Falls es mal nicht weiter geht, ist sie immer für ein Sparring zu haben. Nach dem kreativen Chaos ist der Kopf wieder frei und ein hartnäckiger Bug meist schnell behoben.",
            img1: data.nala1.childImageSharp.fluid,
            img2: data.nala2.childImageSharp.fluid,
          },
          // {
          //   name: "Peter Riehs",
          //   title: "Software Developer",
          //   description:
          //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
          //   img1: data.placeholder1.childImageSharp.fluid,
          //   img2: data.placeholder2.childImageSharp.fluid,
          // },
          // {
          //   name: "Patrick O'Keeffe / AMC Solutions",
          //   title: "Industry Expert",
          //   description:
          //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
          //   img1: data.placeholder1.childImageSharp.fluid,
          //   img2: data.placeholder2.childImageSharp.fluid,
          // },
          // {
          //   name: "Christof Groner / NIKDIN Design (agreement pending)",
          //   title: "UX & UI",
          //   description:
          //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
          //   img1: data.placeholder1.childImageSharp.fluid,
          //   img2: data.placeholder2.childImageSharp.fluid,
          // },
          // {
          //   name: "Alper Özdemir (agreement pending)",
          //   title: "Software Developer",
          //   description:
          //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
          //   img1: data.placeholder1.childImageSharp.fluid,
          //   img2: data.placeholder2.childImageSharp.fluid,
          // },
          // {
          //   name: "Marius Heine / GeProg (agreement pending)",
          //   title: "Software Developer",
          //   description:
          //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
          //   img1: data.placeholder1.childImageSharp.fluid,
          //   img2: data.placeholder2.childImageSharp.fluid,
          // },
          // {
          //   name: "Johannes Raabe / Clarify Data (agreement pending)",
          //   title: "Data Scientist",
          //   description:
          //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
          //   img1: data.placeholder1.childImageSharp.fluid,
          //   img2: data.placeholder2.childImageSharp.fluid,
          // },
          // {
          //   name:
          //     "Friedrich Dürst / dht Consulting & Management (agreement pending)",
          //   title: "Industry Expert",
          //   description:
          //     "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
          //   img1: data.placeholder1.childImageSharp.fluid,
          //   img2: data.placeholder2.childImageSharp.fluid,
          // },
        ]}
      />
    </Section>
  );
};

export default Crew;

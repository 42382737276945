import React from "react";

type ButtonPropTypes = {
  onClick?: () => void;
  children: React.ReactNode;
  light?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  className?: string;
  secondary?: boolean;
};

const transition = "transition ease-in-out duration-300";

const getOutline = (light: boolean, secondary: boolean, disabled: boolean) => {
  var style = `p-0.5 bg-opacity-0 ${transition}`;
  if (disabled) return `${style}`;
  if (light && !secondary) style = `${style} hover:bg-light`;
  if (!light && !secondary) style = `${style} hover:bg-dark`;
  return style;
};

const getUnderline = (
  light: boolean,
  secondary: boolean,
  disabled: boolean
) => {
  var style = `border-b-2 border-opacity-0 ${transition}`;
  if (light) {
    style = `${style} border-light`;
  } else {
    style = `${style} border-dark`;
  }
  if (secondary && !disabled) {
    style = `${style} group-hover:border-opacity-100`;
  }
  return style;
};

const getBackground = (light: boolean, secondary: boolean) => {
  var style = "p-2";
  if (light) {
    style = `${style} bg-light`;
  } else {
    style = `${style} bg-dark`;
  }
  if (secondary) style = `${style} bg-opacity-0`;
  return style;
};

const Button = ({
  onClick,
  children,
  light = false,
  disabled = false,
  type = undefined,
  className = "",
  secondary = false,
}: ButtonPropTypes) => {
  return (
    <button
      type={type}
      className={`${
        disabled ? "opacity-50" : ""
      } group inline-flex items-center justify-center ${getOutline(
        light,
        secondary,
        disabled
      )} ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={`w-full h-full ${getBackground(light, secondary)}`}>
        <div className="px-6">
          <div
            className={`${getUnderline(
              light,
              secondary,
              disabled
            )} text-lg font-medium text-white `}
          >
            {children}
          </div>
        </div>
      </div>
    </button>
  );
};

export default Button;
